import React from 'react'
import { getImage } from 'gatsby-plugin-image'

import LayoutInnerPage from '@src/layouts/InnerPage'
import Seo from '@components/seo/Index'
import RealEstateBanner from '@components/global/banner/RealEstateBanner'
import Best10RealEstate from '@components/global/gallery/Best10RealEstate'
import { Closing } from '@components/pages/best-10-video'
import LeadContactForm from '@components/global/forms/LeadContactForm/Index'

import {
  pageData as page,
  bannerData,
  listData,
  closingData,
} from '@src/data/Best10Video'
import useBest10VideoMetadata from '@hooks/best-10-video-metadata'
import useWindowSize from '@hooks/useWindowSize'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import * as cx from './10BestVideo.module.scss'

const Best10VideoWebsites = () => {
  const {
    bannerBGDesktop,
    bannerBG,
    bannerImg,
    listBG,
    closingImg1,
    closingImg2,
    lcfBG,
  } = useBest10VideoMetadata()
  const isDesktop = useWindowSize().width >= 1024
  const closingImgData = [closingImg1, closingImg2]

  return (
    <LayoutInnerPage
      hasWhiteTransparentNav
      noFloatingShareButtons
      wrapperClassName={cx.wrapper}
    >
      <Seo title={page.title} uri={page.uri} />
      <RealEstateBanner
        wrapperClassName={cx.banner}
        breadcrumbs={page.breadcrumbs}
        heading={bannerData.heading.text}
        subheading={bannerData.subheading.text}
        bg={extractImage(isDesktop ? bannerBGDesktop : bannerBG)}
        img={extractImage(bannerImg)}
      />
      <Best10RealEstate
        hasVideoTablet
        wrapperClassName={cx.list}
        bg={extractImage(listBG)}
        heading={listData.heading.text}
        subheading={listData.subheading.text}
        subheading2={listData.subheading2.text}
        buttonText={listData.list.buttonText}
        items={listData.list.items}
        identifierDelay={listData.list.identifierDelay}
      />
      <Closing
        heading={closingData.heading.text}
        subheading={closingData.subheading.text}
        items={closingData.items}
        imgItems={closingImgData}
      />
      <LeadContactForm
        version={page.lcf.version}
        modelOrBackground={page.lcf.model}
        background={getImage(lcfBG)}
        title={page.lcf.title}
        subtitle={page.lcf.subtitle}
        submitLabel={page.lcf.submitLabel}
        leadName={page.lcf.leadName}
        layout={page.lcf.layout}
      />
    </LayoutInnerPage>
  )
}

export default Best10VideoWebsites

import React from 'react'

export const pageData = {
  title: '10 Best Real Estate Video Websites',
  breadcrumbs: [
    { text: 'Home', url: '/' },
    {
      text: 'Real Estate Websites',
      url: '/real-estate-websites/',
    },
    {
      text: '10 Best Real Estate Video Websites',
      url: '/real-estate-websites/10-best-real-estate-video-websites/',
    },
  ],
  uri: '/real-estate-websites/10-best-real-estate-video-websites/',
  lcf: {
    version: 'LCF04',
    model: 'Gianna',
    title: 'Get a FREE Consultation Today!',
    subtitle: 'INTERESTED IN VIDEO MARKETING?',
    submitLabel: 'BOOK MY APPOINTMENT',
    leadName: 'Consultation: 10 Best Real Estate Video Websites',
    layout: 'B',
  },
}

export const bannerData = {
  heading: {
    text: '10 Best Real Estate Video Websites',
    className: 'heading-5',
  },
  subheading: {
    text: 'Video banners that tell compelling stories',
    className: 'subtitle-7',
  },
}

export const listData = {
  heading: {
    text: 'Video Marketing Meets Real Estate Web Design',
    className: 'heading-5',
  },
  subheading: {
    text: 'THE POWER TO CAPTIVATE',
    className: 'subtitle-7',
  },
  subheading2: {
    text:
      'Real estate is all about making a great first impression and videos allow you showcase properties in a way that static photos simply can’t. The beautiful banner videos we feature draw visitors deeper into your website, giving you more traffic and more leads. Take a look at the impressive work we’ve done for the industry’s most respected agents and brokers.',
    className: 'subtitle-5',
  },
  list: {
    titleClassName: 'heading-3',
    descriptionClassName: 'default-body large',
    buttonText: 'GET PRICING & FEATURES',
    identifierDelay: { update: 0, intersect: 50 },
    items: [
      {
        title: 'Shannon Gillette',
        location: 'Scottsdale, AZ',
        description:
          'For Shannon Gillette, homes are more than just structures—they’re gateways to an aspirational lifestyle. So with the strategic use of videos, GilletteGroupAZ.com feels less like a real estate web page and more like a glossy lifestyle brand. It also features the Gillette Group’s Home of the Day video campaign, a cutting-edge marketing listing technique that fast-tracks home sales. This is a great example of using ample white space and a sleek layout further imbue the site with effortless elegance.',
        videoUrl: 'https://player.vimeo.com/video/510609335',
      },
      {
        title: 'The Pratt Group',
        location: 'Coachella Valley, CA',
        description:
          'Coachella Valley has so much to offer. From exquisite living spaces to breathtaking landscapes and landmarks, the quintessential Southern California luxury lifestyle awaits in this incredible region. It’s a testament to The Pratt Group’s technological savvy and vision that their professional site captures all the highlights of the local lifestyle in a full-screen video montage that clocks in at no more than a minute. After the captivating video banner, ThePrattGroup.com follows up strong, revealing rich features and informative resources as you scroll further down.',
        videoUrl: 'https://player.vimeo.com/video/542036135',
      },
      {
        title: 'The Noel Team',
        location: 'Santa Monica, CA',
        description:
          'From the sky to the streets to the interior of Santa Monica luxury homes, TheNoelTeam.net successfully paints a complete picture of the Southern California lifestyle, thanks to the lively video banner. The site conveys the sweep and promise of Santa Monica’s luxury living even if you’re not there yourself. The Noel Team enhances the effect of the video by adding a soundtrack that elevates the whole user experience.',
        videoUrl: 'https://player.vimeo.com/video/344738158',
      },
      {
        title: 'Greg Burns',
        location: 'Maui, HI',
        description:
          '“Your story begins here,” as the welcome text on IsleLuxury.com says. From footage of Realtor Greg Burns jumping out of a helicopter into the ocean to the overhead view of boaters paddling alongside a beautiful humpback whale, its video homepage is dressed to impress. Exhibiting every dream-like scenario that a Hawaii home turns into reality, this luxury real estate will likewise make you want to take that leap.',
        videoUrl: 'https://player.vimeo.com/video/284338108',
      },
      {
        title: 'The Chernov Team',
        location: 'Studio City, CA',
        description:
          'The video banner at the top of ChernovTeam.com doesn’t just give visitors a peek into deluxe properties, it also gives them a taste of what it’s like to live in these luxurious homes. These engaging clips emulate the experience of walking through these homes, leaving an impression that static words or videos can only hope to achieve. The generous use of white space and a red-tinged color palette further establish the breezy but vibrant lifestyle that awaits in the San Fernando Valley.',
        videoUrl: 'https://player.vimeo.com/video/724341357',
      },
      {
        title: 'Bill Fandel',
        location: 'Telluride, CO',
        description:
          'Load TellurideAdvisors.com on your browser and be dazzled by incredible upscale homes and landscapes set against a gorgeous mountain wilderness backdrop. This site makes excellent use of video content to illustrate the scale of the luxurious estates and acreage available in this southwestern Colorado town. Aerial footage, artistic panning, and dramatic timelapse footage will capture your imagination and leave you wanting a piece of the wild country.',
        videoUrl: 'https://player.vimeo.com/video/722951274',
      },
      {
        title: 'Stacy Gottula',
        location: 'Beverly Hills, CA',
        description:
          'Luxury real estate in Los Angeles is more than just about the homes – it’s as much about the lifestyle too. This is clearly conveyed by the enthralling welcome video on StacyGottula.com. The high quality composition of sweeping aerial shots and street scenes highlight the best that LA has to offer. From luxury shopping in Rodeo Drive to the active lifestyle in upscale beach communities, Stacy Gottula knows Los Angeles luxury real estate like the back of her hand – and her dynamic site says it best.',
        videoUrl: 'https://player.vimeo.com/video/722951226',
      },
      {
        title: 'Roh Habibi',
        location: 'San Francisco, CA',
        description:
          'High-class real estate meets high-profile Realtor meets high-quality agent website – this is RohHabibi.com in a nutshell. Decked with a dynamic video slider on its homepage, this luxury real estate site captures the prestige of the Bay Area like no other real estate site does. The towering condos, the waterfront neighborhoods, and global real estate expert Roh Habibi himself in action are all on full display here.',
        videoUrl: 'https://player.vimeo.com/video/284338789',
      },
      {
        title: 'Christophe Choo',
        location: 'Beverly Hills, CA',
        description:
          'ChristopheChoo.com truly knows how to use video to make a grand entrance. When you first load the site, you are treated to a sunset view of Beverly Hills — a taste of the luxurious lifestyle that awaits you. Click “Enter Website” and you’re immediately transported into the stunning homes that make this city an iconic address. Of course, serving as your gracious guide is Christophe Choo, who’s truly in his element when he’s showcasing homes that redefine what refined living means. Is it any wonder why this site took home the best video award at the 2021 RealTrends Website Rankings?',
        videoUrl: 'https://player.vimeo.com/video/688723105',
      },
      {
        title: 'Mike + Maria Patakas',
        location: 'Palm Springs, CA',
        description:
          'The welcome video of MikeandMariaHomes.com fascinates with a seamless vignette of the Greater Palm Springs luxury lifestyle. As one of the leading real estate teams in the metro, Mike and Maria Patakas designed their web presence with the most discriminating home buyers in mind, starting with an engaging video that aptly captures what their clientele is all about. Opulent interiors, distinctive architecture, and stunning vistas whet site visitors’ appetite for the way of life waiting for them in this market.',
        videoUrl: 'https://player.vimeo.com/video/417076229',
      },
    ],
  },
}

export const closingData = {
  heading: {
    text: 'Evocative & Effective',
    className: 'heading-2',
  },
  subheading: {
    text: 'IMPRESS PROSPECTS WITH VIDEO CONTENT',
    className: 'subtitle-7',
  },
  items: [
    {
      title: 'Show The Bigger Picture',
      description:
        'People don’t just want to see a home — they want to imagine what it’s like to live there. Videos are well-suited for real estate marketing because they give buyers a three-dimensional view of a place they might soon call home. It’s like giving them a preview of the property before their in-person viewing.',
      buttonText: 'NEED HELP CREATING VIDEOS?',
      imgAlt:
        'The Noel Team and the Pratt Group websites screenshots on laptop',
      link: '/real-estate-internet-marketing/custom-solutions/',
    },
    {
      title: 'Get a Tactical Advantage',
      description:
        'Aside from being impressive, videos also help you get an edge over the competition. To begin with, data from Inman shows that 85% of buyers and sellers prefer working with an agent who uses video marketing. Furthermore, it makes people linger longer on your website — a crucial metric that can enhance your search engine ranking. Most importantly, 88% of marketers report a positive ROI from video content.',
      buttonText: 'TALK TO US ABOUT VIDEO MARKETING',
      imgAlt: 'Stacy Gottula and Isle Luxury websites screenshots on laptop',
      link: '/real-estate-internet-marketing/custom-solutions/',
    },
  ],
}

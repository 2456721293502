import { useStaticQuery, graphql } from 'gatsby'

const useBest10VideoMetadata = () => {
  const query = useStaticQuery(graphql`
    query best10VideoMetadata {
      bannerBGDesktop: file(
        relativePath: { eq: "best-10-video/banner/bg-desktop.jpg" }
      ) {
        id
        ...Best10VideoImageOptimize
      }
      bannerBG: file(relativePath: { eq: "best-10-video/banner/bg.jpg" }) {
        id
        ...Best10VideoImageOptimize
      }
      bannerImg: file(relativePath: { eq: "best-10-video/banner/img.png" }) {
        id
        ...Best10VideoImageOptimize
      }

      listBG: file(relativePath: { eq: "best-10-video/list/bg.jpg" }) {
        id
        ...Best10VideoImageOptimize
      }
      closingImg1: file(
        relativePath: { eq: "best-10-video/closing/img-1.png" }
      ) {
        id
        ...Best10VideoImageOptimize
      }
      closingImg2: file(
        relativePath: { eq: "best-10-video/closing/img-2.png" }
      ) {
        id
        ...Best10VideoImageOptimize
      }
      lcfBG: file(relativePath: { eq: "best-10-video/others/lcf-bg.jpg" }) {
        id
        ...Best10VideoImageOptimize
      }
    }

    fragment Best10VideoImageOptimize on File {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, quality: 90, formats: [AUTO])
      }
    }
  `)

  return query
}

export default useBest10VideoMetadata

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import clsx from 'clsx'

import ElementImage from '@components/global/element/Image'
import { Button } from '@components/global/buttons/Buttons'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import * as cx from './Closing.module.scss'

const Card = ({
  title,
  description,
  buttonText,
  img,
  imgAlt,
  link,
  titleClassName = 'heading-3',
  descriptionClassName = 'subtitle-5',
}) => {
  return (
    <div className={cx.card}>
      <div className={cx.innerContainer}>
        <div className={cx.image}>
          <ElementImage src={extractImage(img)} alt={imgAlt} />
        </div>
        <div className={cx.content}>
          <h4 className={titleClassName}>{title}</h4>
          <p className={descriptionClassName}>{description}</p>
          <Link to={link}>
            <Button text={buttonText} />
          </Link>
        </div>
      </div>
    </div>
  )
}

const Closing = ({
  wrapperClassName,
  heading,
  subheading,
  headingClassName = 'heading-2',
  subheadingClassName = 'subtitle-7',
  items,
  imgItems,
}) => {
  return (
    <section className={clsx([cx.wrapper], wrapperClassName)}>
      <h3 className={cx.header}>
        <span className={headingClassName}>{heading}</span>
        <span className={subheadingClassName}>{subheading}</span>
      </h3>
      <div className={cx.container}>
        {items?.map((item, key) => {
          return (
            <Card
              key={key}
              title={item.title}
              description={item.description}
              buttonText={item.buttonText}
              img={imgItems[key]}
              imgAlt={item.imgAlt}
              link={item.link}
            />
          )
        })}
      </div>
    </section>
  )
}

Closing.propTypes = {
  wrapperClassName: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  headingClassName: PropTypes.string,
  subheadingClassName: PropTypes.string,
  items: PropTypes.array,
  imgItems: PropTypes.array,
}

export default Closing
